<template>
  <div class="container page-404">
    <span class="error-span">404... error...</span>
  </div>
</template>

<script>
export default {
  name: "404.vue",
};
</script>

<style scoped>
div.container.page-404 span.error-span {
  font-family: "Ubuntu", sans-serif;
  font-size: 30vh;
  font-weight: bold;
  color: #fff9e7;
}
</style>
